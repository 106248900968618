.content {
  color: var(--color-gray-600);
  font-size: medium;
  font-family: sans-serif;
  min-width: 80%;
  margin-left: 10px;
  margin-right: 10px;
}

.messaging {
  margin-top: 15px;
  text-align: center;
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  margin-top: 15px;
  text-align: center;
}

.loading {
  margin-top: 15px;
  text-align: center;
}

.indicator {
  color: var(--color-gray-600) !important;
  height: 24px !important;
  width: 24px !important;
  margin-right: 5px;
}

.success .SuccessIcon {
  margin-right: 2px;
  color: #25bf2c;
  margin: 20px;
  font-size: 100px !important;
}

.error .ErrorIcon {
  color: #d32f2f;
  margin: 15px;
  font-size: 100px !important;
}

.alert {
  margin: 20px;
  width: fit-content;
}

.alertContainer {
  display: flex;
  justify-content: center;
}

.openInvitationsContainer {
  margin: 10px;
}

.black-button {
  text-transform: inherit !important;
}

.MuiButton-containedSizeMedium.black-button {
  font-weight: 600 !important;
  height: 43px;
  padding: 12px 24px;
  border-radius: 6px;
  gap: 8px;
}

.protocol-handler-message {
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.protocol-handler-message .header {
  margin-bottom: 1rem;
  color: var(--color-gray-900);
}

.protocol-handler-message .description {
  color: var(--color-gray-700);
  margin-bottom: 2rem;
}

.protocol-handler-message .retry-section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.protocol-handler-message .not-visible {
  color: var(--color-gray-600);
  font-size: 0.9rem;
}