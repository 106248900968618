/* BUTTONS */

@media only screen and (max-width: 600px) {
  .MuiButton-root.black-button .MuiButton-root.grey-button {
    width: 80vw !important;
  }

  .MuiButton-root.icon-button {
    width: 80vw !important;
  }
}

.MuiButton-root.black-button {
  width: max-content;
  min-width: 200px;
  height: 43px;
  border-radius: 6px;
  text-transform: inherit !important;
}

.MuiButton-root.black-button:hover {
  background-color: var(--color-gray-800);
  color: #fff;
}

.MuiButton-root.grey-button {
  width: max-content;
  min-width: 200px;
  height: 43px;
  border-radius: 6px;
  text-transform: inherit !important;
  background-color: var(--color-gray-200);
  color: #000;
}

.MuiButton-root.grey-button:hover {
  background-color: var(--color-gray-300);
}

.MuiButton-textSizeMedium.icon-button {
  border: 1px solid var(--color-gray-200);
  border-radius: 6px !important;
  padding: 12px 16px 12px 16px;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  color: #000;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-gray-100);
  justify-content: space-between;
  margin-top: 4px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.MuiButton-textSizeMedium.double-text-button {
  border: 1px solid var(--color-gray-200);
  border-radius: 6px !important;
  padding: 12px 16px 12px 16px;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  color: #000;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-gray-100);
  justify-content: space-between;
  flex-direction: row;
  margin-top: 4px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.MuiButton-textSizeMedium.double-text-button .button-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.MuiButton-textSizeMedium.double-text-button .description {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  width: 100%;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--color-gray-500);
}

.MuiButton-textSizeMedium.double-text-button .title {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  width: 100%;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--color-gray-900);
}


.skip-button {
  text-transform: inherit !important;
}

.refresh-button {
  text-transform: inherit !important;
}